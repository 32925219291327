import React, { FC, useRef } from 'react';
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import styles from "./search.module.css";
import { translate } from '../../i18n';

interface SearchProps {
    value: string;
    onChange: (newVal: string) => void;
    lang: "en" | "ru";
}

const Search: FC<SearchProps> = ({ onChange, value, lang }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <div className={styles.searchWrapper}>
            <div className={styles.inputWrapper}>
                <input
                    type="text"
                    ref={inputRef}
                    className={styles.input}
                    value={value}
                    placeholder={translate('search', lang)}
                    onChange={(event) => onChange(event.target.value)}
                />
                <SearchIcon onClick={() => inputRef?.current?.focus()} />
            </div>
        </div>
    );
};

export default Search;
