import { createContext, ReactNode, useContext, useState } from "react";
interface PlayerContextType {
  handlePlayPause: () => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<boolean>;
}
const PlayerContext = createContext<PlayerContextType | undefined>(undefined);
export const PlayerPrivider = ({ children }: { children: ReactNode }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <PlayerContext.Provider
      value={{ isPlaying, setIsPlaying, handlePlayPause }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
export const usePlayer = (): PlayerContextType => {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error("useUser must be used within an PlayerContext");
  }
  return context;
};
