import { FC, useEffect, useState } from "react";
import style from "./AlbumSongs.module.css";
import { ReactComponent as NoteIcon } from "../../assets/icons/stationMusicIcon.svg";
import Icon from "../../assets/icons/favoriteIcon.png";
import FavoriteActive from "../../assets/icons/FavoriteActive.png";
import { useFavorites } from "../../context/FavoritesContext";
import { useUser } from "../../context/UserContext";
import { MusiciansAlbumSongsResponse } from "../../service/music/music";
import { useMusic } from "../../context/MusicContext";
import { usePlayer } from "../../context/PlayerContext";
import { ReactComponent as Back } from "../../assets/icons/back.svg";


interface AlbumSongsProps {
  albumId: number;
  musicianId: number;
  onBackClick: () => void;
}

export const AlbumSongs: FC<AlbumSongsProps> = ({
  albumId,
  musicianId,
  onBackClick,
}) => {
  const {
    musicians,
    albums,
    songs,
    song,
    handleGetAlbumsSongs,
    handleGetMusicianAlbums,
    handleSelectSong,
    setIsPlayingFromFavorites,
    handlePostLastSong,
  } = useMusic();
  const {
    musicFavorites,
    handleToggleMusicFavorite,
    handleGetMusicFavoritesByUser,
  } = useFavorites();
  const { user } = useUser();
  const { setIsPlaying } = usePlayer();

  const musician = musicians.find((musician) => musician.id === musicianId);
  const album = albums.find((album) => album.id === albumId);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleFavoriteClick = async (
    event: React.MouseEvent,
    song: MusiciansAlbumSongsResponse
  ) => {
    if (isProcessing) return;
    setIsProcessing(true);

    event.stopPropagation();
    try {
      await handleToggleMusicFavorite(song, user.telegram_id);
    } catch (error) {
      console.error("Failed to update favorites", error);
    } finally {
      setIsProcessing(false);
    }
  };

  console.log("Album ID", albumId);

  useEffect(() => {
    if (musicianId && !albums.length) {
      handleGetMusicianAlbums(musicianId);
    }

    if (albumId) {
      handleGetAlbumsSongs(albumId);
    }
  }, [albumId, musicianId, user]);

  return (
    <div>
      <h1 className={style.nameMusician}>{musician?.name}</h1>
      <img
        src={musician?.photo}
        alt={musician?.name}
        className={style.photoMusician}
      />

      <div className={style.albumTitle}>
        <h3 className={style.albumName}>{album?.title}</h3>
      </div>

      <div className={style.wrapperList}>
        {songs.map((son) => (
          <div
            className={`${style.itemWrapper} ${
              son.id === song?.id ? style.active : ""
            }`}
            key={son.id}
            onClick={() => {
              handleSelectSong(son);
              setIsPlaying(true);
              setIsPlayingFromFavorites(false);
              handlePostLastSong(user.telegram_id, son.id);
            }}
          >
            <button className={style.playButton}>
              <NoteIcon />
            </button>
            <p
              className={style.title}
            >{`${son.musician.name} - ${son.title}`}</p>
            <div
              className={style.favoriteIcon}
              onClick={(event) => {
                handleFavoriteClick(event, son);
              }}
            >
              {musicFavorites?.find((fav) => fav.song.id === son.id) ? (
                <img
                  src={FavoriteActive}
                  alt="Remove from favorites"
                  className={style.favoriteActiveimg}
                />
              ) : (
                <img
                  src={Icon}
                  alt="Add to favorites"
                  className={style.favoriteimg}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div
            className={style.fixBack}
            onClick={onBackClick}
          >
            <Back />
          </div>    </div>
  );
};
