import React from "react";
import { Slider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import style from "./TimeControl.module.css";

const TimeContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "0",
  color: "#FFFFFF",
}));

const TimeSlider = styled(Slider)(({ theme }) => ({
  color: "#4A78F1",
  height: 6,
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    background: "#9C5397",
    boxShadow: "0px 4px 4.9px rgba(234, 173, 255, 0.55) inset",
  },
  "& .MuiSlider-track": {
    border: "none",
    background:
      "linear-gradient(90deg, rgba(213, 2, 255, 0.7) 0%, rgba(0, 179, 255, 0.7) 100%)",
    backdropFilter: "blur(20.3px)",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "rgba(110, 13, 167, 0.2)",
  },
}));

interface TimeControlProps {
  elapsedTime: number;
  totalTime: number;
  onTimeChange: (newTime: number) => void;
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
};

const TimeControl: React.FC<TimeControlProps> = ({
  elapsedTime,
  totalTime,
  onTimeChange,
}) => {
  const handleTimeChange = (event: Event, newValue: number | number[]) => {
    onTimeChange(newValue as number);
  };

  return (
    <>
      <TimeContainer>
        <TimeSlider
          value={elapsedTime}
          max={totalTime}
          onChange={handleTimeChange}
          aria-labelledby="time-slider"
        />
        <div className={style.wrapperTime}>

        <Typography>
          <p className={style.text}>{formatTime(elapsedTime)}</p>{" "}
        </Typography>
        <Typography>
          <p className={style.text}>{formatTime(totalTime)}</p>{" "}
        </Typography>
        </div>
      </TimeContainer>
    </>
  );
};

export default TimeControl;
