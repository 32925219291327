import React, { FC } from "react";
import styles from "../Onboarding.module.css";
import { useTranslate } from "../../../i18n";

interface OnboardingInterface {
  username: string;
}

const Step3: FC<OnboardingInterface> = ({ username }) => {
  const translate = useTranslate();
  const step = translate.help_page.invite;
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img3}></div>

      <p className={styles.subtitle}>{step.title}</p>

      <div className={`${styles.textContent} ${styles.textContentCenter}`}>
        <p className={styles.text}>{step.text_1}</p>
        <p className={styles.text}>{step.text_2}</p>
        <p className={styles.text}>{step.text_3}</p>
        <p className={styles.blueText}>{step.text_4}</p>
      </div>
    </div>
  );
};

export default Step3;
