import React from 'react';
import { Slider, IconButton } from '@mui/material';
import { ReactComponent as VolumeIcon } from "../../assets/icons/player/volumtIcon.svg";
import { styled } from '@mui/system';

const VolumeContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '16px  2px 0  0 ',
    color: '#FFFFFF'
}));

const VolumeSlider = styled(Slider)(({ theme }) => ({
    color: '#4A78F1',
    height: 8,
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        background: '#9C5397',
        boxShadow: '0px 4px 4.900000095367432px rgba(234.55, 173.19, 255, 0.55) inset',
    },
    '& .MuiSlider-track': {
        border: 'none',
        background: 'linear-gradient(90deg, rgba(213, 2, 255, 0.7) 0%, rgba(0, 179, 255, 0.7) 100%)',
        backdropFilter: 'blur(20.299999237060547px)',
},
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
}));

interface VolumeControlProps {
    volume: number;
    onVolumeChange: (volume: number) => void;
}

const VolumeControl: React.FC<VolumeControlProps> = ({ volume, onVolumeChange }) => {
    const handleVolumeChange = (event: Event, newValue: number | number[]) => {
        onVolumeChange(newValue as number);
    };

    return (
        <VolumeContainer>
            <IconButton onClick={() => onVolumeChange(0)} aria-label="volume">
                <VolumeIcon />
            </IconButton>
            <VolumeSlider value={volume} onChange={handleVolumeChange} aria-labelledby="continuous-slider" />
        </VolumeContainer>
    );
};

export default VolumeControl;
