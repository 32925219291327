import { useEffect, useState } from "react";
import { CatalogMusicians } from "../../components/CatalogMusicians/CatalogMusicians";
import { useFavorites } from "../../context/FavoritesContext";
import { useUser } from "../../context/UserContext";
import Filter from "../../components/Search/Filter/Filter";
import Search from "../../components/Search/Search";
import { MusicianAlbums } from "../../components/MusicianAlbums/MusicianAlbums";
import { FavoritesMusic } from "../../components/FavoritesMusic/FavoritesMusic";
import { FilteredCombined } from "../../components/FilteredCombined/FilteredCombined";
import { translate } from "../../i18n";

import style from "./Musicians.module.css";
import { getCountries, getGenres } from "../../service/stations/stations";

export const Musicians: React.FC<{
  lang: "en" | "ru";
  tab: 0 | 1;
}> = ({ lang, tab }) => {
  const [activeTab, setActiveTab] = useState(tab);
  const [value, setValue] = useState("");
  const [genres, setGenres] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [activeGenre, setActiveGenre] = useState(0);
  const [activeCountry, setActiveCountry] = useState(0);

  const [selectedMusicianId, setSelectedMusicianId] = useState<number | null>(
    null
  );

  const handleClickMusician = (musicianId: number) => {
    setSelectedMusicianId(musicianId);
  };
  const { user } = useUser();
  const { handleGetMusicFavoritesByUser } = useFavorites();

  const handleChange = (val: string) => setValue(val);

  const handleClickBack = () => {
    setSelectedMusicianId(null)
  }

  useEffect(() => {
    if (user) {
      handleGetMusicFavoritesByUser(user.telegram_id);
    }
  }, [user]);

  return (
    <>
      <div className={style.topButtons}>
        <button
          className={`${style.claimButton} ${
            activeTab === 0 ? style.active : ""
          }`}
          onClick={() => {
            setActiveTab(0);
          }}
        >
          <p>Artists</p>
        </button>
        <button
          className={`${style.claimButton} ${
            activeTab === 1 ? style.active : ""
          }`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          <p>Favorites</p>
        </button>
      </div>
      <Search
        value={value}
        onChange={handleChange}
        lang={lang}
      />

      {!selectedMusicianId ? (
        <>
          <div className={style.contentWrapper}>
            {activeTab === 0 ? (
              <>
                {value ? (
                  <FilteredCombined
                    value={value}
                    handleClickMusician={handleClickMusician}
                    activeTab={activeTab}
                  />
                ) : (
                  <>
                    <CatalogMusicians />
                  </>
                )}
              </>
            ) : (
              <>
                <FavoritesMusic
                  value={value}
                  activeTab={activeTab}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <MusicianAlbums musicianId={selectedMusicianId} onBackClick={handleClickBack} />
      )}
    </>
  );
};
