import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import Stack from "@mui/material/Stack";
import { useMusic } from "../../context/MusicContext";
import { useUser } from "../../context/UserContext";
import { useEffect, useState } from "react";

export const StarRating = () => {
  const [hover, setHover] = useState(-1);
  const { song, handlePostRateSong } = useMusic();
  const [value, setValue] = useState<number>(Number(song?.average_rating));
  const { user } = useUser();
  useEffect(() => {
    setValue(Number(song?.average_rating));
  }, [song]);
 
  return (
    <Box
      sx={{
        width: "128px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "17px",
      }}
    >
      <Rating
        name="customized-10"
        sx={{
          color: "rgba(38, 163, 218, 1)",
          fontSize: "20px",

          "& .MuiRating-iconFilled": {
            color: "rgba(38, 163, 218, 1)",
          },
          "& .MuiRating-iconEmpty": {
            color: "rgba(38, 163, 218, 1)",
          },
        }}
        defaultValue={Number(song?.average_rating)}
        value={value}
        max={5}
        precision={0.5}
        onChange={(event, newValue) => {
          if (song && newValue && user) {
            handlePostRateSong(
              user.telegram_id.toString(),
              song?.id.toString(),
              newValue.toString()
            );
            setValue(newValue);
          }
        }}
      />
    </Box>
  );
};
