import React, { FC } from "react";

import styles from "../Onboarding.module.css";
import { useTranslate } from "../../../i18n";

interface OnboardingInterface {
  username: string;
}

const Step1: FC<OnboardingInterface> = ({ username }) => {
  const translate = useTranslate();
  const step = translate.onboarding.step_1;
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.title}>{`${step.title} ${username}!`}</h1>

      <div className={styles.img}></div>

      <p className={styles.subtitle}>{step.subtitle_1}</p>
      <p className={styles.subtitle}>{step.subtitle_2}</p>

      <div className={styles.textContent}>
        <p className={styles.text}>
          {step.text_1}
        </p>
        <p className={styles.text}>
        {step.text_2}
        </p>
        <p className={styles.text}>
        {step.text_3}
        </p>
        <p className={styles.text}>{step.text_4}</p>
      </div>
    </div>
  );
};

export default Step1;
