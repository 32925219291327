import style from "./Button.module.css";

interface Props {
  access: boolean;
  text: string;
  onClick?: () => void;
}

export const Button: React.FC<Props> = ({ access, text, onClick }) => {
  return (
    <button onClick={onClick} className={style.button_wrapper}>
      <span className={  access ? style.whiteText : style.redText}>{text}</span>
    </button>
  );
};
