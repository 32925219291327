import axios, { AxiosInstance } from 'axios';
export const BASE_SCHEME = 'https'
export const BASE_ORIGIN = "radiogram.site"
export const BASE_URL = `${BASE_SCHEME}://${BASE_ORIGIN}` // `${BASE_SCHEME}://${BASE_ORIGIN}` // "http://0.0.0.0:8000/" //

const axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        "Authorization": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWTYxNjIzOTAyMn0.'
    },
});
axiosInstance.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        if (error.response && error.response.data && error.response.data.message) {
            throw new Error(error.response.data.message);
        } else {
            throw error;
        }
    },
);

export { axiosInstance };
