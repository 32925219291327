import React, { useEffect, useState } from "react";
import TabNavigation from "./components/TabNavigation/TabNavigation";
import Menu from "./components/Menu/Menu";
import Player from "./pages/player/Player";
import Stations from "./pages/stations/Stations";
import Copyright from "./pages/copyright/Copyright";
import RefLink from "./pages/refLink/RefLink";
import { BASE_ORIGIN } from "./service/axiosConfig/axiosConfig";
import { I18nProvider, translate } from "./i18n";
import { UserStatus } from "./components/UserStatus/UserStatus";
import Onboarding from "./components/Onboarding/Onboarding";
import Header from "./components/Header/Header";
import Tasks from "./pages/tasks/Tasks";
import Wallet from "./pages/wallet/wallet";
import Help from "./pages/help/help";
import { useUser } from "./context/UserContext";
import UserAgreement from "./pages/userAgreement/userAgreement";
import { CatalogMusicians } from "./components/CatalogMusicians/CatalogMusicians";
import { AlbumSongs } from "./components/AlbumSongs/AlbumSongs";
import { MusicianAlbums } from "./components/MusicianAlbums/MusicianAlbums";
import { Musicians } from "./pages/musicians/Musicians";
import PlayerControlsMusicians from "./components/PlayerControlsMusicians/PlayerControlsMusicians";
import { FavoritesMusic } from "./components/FavoritesMusic/FavoritesMusic";
import { useMusic } from "./context/MusicContext";

// @ts-ignore
export const tg = window.Telegram.WebApp;

const App: React.FC = () => {
  React.useEffect(() => {
    tg.ready();
    tg.expand();
    tg.SettingsButton.show();
    tg.SettingsButton.onClick();
    tg.disableVerticalSwipes();
  });

  const { user, handleGetUserByTelegramId } = useUser();
  const [lang, setLang] = useState("en");

  const urlParams = new URLSearchParams(window.location.search);
  const telegramId = urlParams.get("telegram_id");
  const { song } = useMusic();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [onArtistOpen, setOnArtistOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const maxReconnectAttempts = 3;

  useEffect(() => {
    if (onArtistOpen) {
      setValue(1);
    }
  }, [onArtistOpen]);

  const fetchUser = async () => {
    setLoading(true);
    try {
      if (telegramId) {
        await handleGetUserByTelegramId(telegramId as string);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const connectWebSocket = () => {
    // @ts-ignore
    const sessionId = window?.Telegram?.WebApp?.initDataUnsafe?.auth_date;
    // @ts-ignore
    const device = window?.Telegram?.WebApp?.platform;
    const langParam = urlParams.get("lang") || "en";
    setLang(langParam);

    if (telegramId && sessionId && device) {
      const newWs = new WebSocket(
        `wss://${BASE_ORIGIN}/ws/users/${telegramId}/${sessionId}/`
      );

      newWs.onopen = () => {
        setIsConnected(true);
        setReconnectAttempts(0);
      };

      newWs.onclose = () => {
        console.log("Connection closed");
        setIsConnected(false);
        setError(translate("session_ended", lang as "en" | "ru"));
        attemptReconnect();
      };

      newWs.onerror = (error) => {
        console.log("WebSocket error:", error);
        setIsConnected(false);
        setError(translate("something_wrong", lang as "en" | "ru"));
        attemptReconnect();
      };

      setWs(newWs);
    } else {
      // setError(translate('params_error', lang as "en" | "ru"));
    }
  };

  const attemptReconnect = () => {
    if (reconnectAttempts < maxReconnectAttempts) {
      setTimeout(() => {
        setReconnectAttempts((prev) => prev + 1);
        connectWebSocket();
      }, 5000);
    } else {
      console.error("Max reconnect attempts reached. Could not reconnect.");
    }
  };

  useEffect(() => {
    fetchUser();
    connectWebSocket();

    return () => {
      ws?.close();
    };
  }, []);

  if (error) {
    return (
      <div className="error-container">
        <h2
          style={{ color: "#ffffff", textAlign: "center", lineHeight: "36px" }}
        >
          {error}
        </h2>
      </div>
    );
  }

  const handleMenuOpen = () => setOpen(true);
  const handleMenuClose = () => setOpen(false);
  const handleSetValue = (newVal: number) => setValue(newVal);
  const handleArtistOpen = () => setOnArtistOpen(!onArtistOpen);

  return (
    <I18nProvider lang={lang as "en" | "ru"}>
      {!loading && user && !user?.is_terms_accepted && (
        <Onboarding username={user?.first_name} />
      )}
      <div className={"wrapper"}>
        <Header
          onMenuOpen={handleMenuOpen}
          onArtistOpen={handleArtistOpen}
          isArtistOpen={onArtistOpen}
        />
        {open && (
          <Menu
            lang={lang as "en" | "ru"}
            onTabChange={handleSetValue}
            onClose={handleMenuClose}
          />
        )}

        <div className="pageContent">
          {!onArtistOpen ? (
            <>
              <div style={{ display: value === 0 ? "block" : "none" }}>
                <Player
                  onTabChange={handleSetValue}
                  lang={lang as "en" | "ru"}
                />
              </div>
              {value === 1 && (
                <Stations
                  lang={lang as "en" | "ru"}
                  setStation={handleSetValue}
                />
              )}
              {value === 2 && <RefLink lang={lang as "en" | "ru"} />}
              {value === 3 && <Tasks lang={lang as "en" | "ru"} />}
              {value === 4 && <Wallet lang={lang as "en" | "ru"} />}
              {value === 5 && <Help />}
              {value === 6 && <Copyright lang={lang as "en" | "ru"} />}
              {value === 7 && <UserStatus />}
              {value === 8 && <UserAgreement button={false} />}
            </>
          ) : (
            <>
              <div style={{ display: value === 0 ? "block" : "none" }}>
                <PlayerControlsMusicians
                  onTabChange={handleSetValue}
                  lang={lang as "en" | "ru"}
                />
              </div>
              {value === 1 && (
                <Musicians
                  tab={0}
                  lang={lang as "en" | "ru"}
                />
              )}
              {value === 2 && (
                <Musicians
                  tab={1}
                  lang={lang as "en" | "ru"}
                />
              )}
              {value === 5 && <Help />}
              {value === 6 && <Copyright lang={lang as "en" | "ru"} />}
              {value === 7 && <UserStatus />}
              {value === 8 && <UserAgreement button={false} />}
            </>
          )}
        </div>

        <TabNavigation
          menuOpen={open}
          onMenuOpen={handleMenuOpen}
          lang={lang as "en" | "ru"}
          value={value}
          setValue={setValue}
          isArtistOpen={onArtistOpen}
        />
      </div>
    </I18nProvider>
  );
};

export default App;
