import React, { FC, MouseEventHandler } from 'react';
import styles from "./menu.module.css";
import { useTranslate } from '../../i18n';

interface MenuProps {
    onClose: () => void;
    onTabChange: (value: number) => void;
    lang: "en" | "ru";
}

const Menu: FC<MenuProps> = ({ onClose, onTabChange }) => {
    const translate = useTranslate();

    const handleMenuWrapperClick: MouseEventHandler<HTMLDivElement> = (event) => {
        onClose();
    }

    const handleMenuItemClick = (value: number) => {
        onTabChange(value);
        onClose();
    }

    return (
        <div onClick={handleMenuWrapperClick} className={styles.menuWrapper}>
            <div onClick={(event) => event.stopPropagation()} className={styles.menu}>
                <div onClick={() => handleMenuItemClick(7)} className={styles.menuItem}>
                    {translate.leaderboard}
                </div>
                <div className={`${styles.menuItem} ${styles.disabled}`}>
                    {translate.game}
                </div>
                <div onClick={() => handleMenuItemClick(5)} className={styles.menuItem}>
                    {translate.help}
                </div>
                <a
                    href="https://t.me/RADIOGRAM_feedback_bot"
                    target="_blank"
                    className={styles.menuItem}
                    rel="noopener noreferrer"
                >
                    {translate.feedback}
                </a>
                <div onClick={() => handleMenuItemClick(6)} className={styles.menuItem}>
                    {translate.copyright}
                </div>
                <div onClick={() => handleMenuItemClick(8)} className={styles.menuItem}>
                    {translate.user_agreement.title}
                </div>
            </div>
        </div>
    );
};

export default Menu;
