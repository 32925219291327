import { FC } from "react";
import style from "./ComingSoon.module.css";
import { ReactComponent as CloseIcon } from "../../assets/icons/closePopupIcon.svg";

interface Props {
  onClose: () => void;
}
export const ComingSoon: FC<Props> = ({ onClose }) => {
  return (
    <div className={style.onboardingWrapper}>
      <div className={style.contentWrapper}>
        <CloseIcon
          onClick={onClose}
          className={style.closeIcon}
        />
        <h1 className={style.title}>Coming Soon</h1>
      </div>
    </div>
  );
};
