import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

import {
  getUser,
  startSession,
  updateLoginStreak,
  UserInterface,
} from "../service/users/users";

interface UserContextType {
  user: UserInterface;
  setUser: React.Dispatch<any>;
  handleGetUserByTelegramId: (id: string) => Promise<void>;
  handleStartSession: (stationId: string, telegramId: string) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const telegramId = urlParams.get("telegram_id") || "458982324";

  const handleGetUserByTelegramId = async (id: string): Promise<void> => {
    try {
      const user = await getUser(id as string);

      if (user) {
        setUser(user);
      } else {
        console.error("Failed to fetch User");
      }
    } catch (error) {
      console.error("Failed to fetch User", error);
    }
  };

  const handleStartSession = async (telegramId: string) => {
    try {
      await startSession({
        telegram_id: telegramId,
        session_id: "1448",
        device: "iPhone",
      });
    } catch (error) {
      console.error("Failed to update user", error);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const fetchStreak = async (telegramId: string) => {
    if (telegramId && !user && !isLoading) {
      setIsLoading(true);
      try {
        await handleGetUserByTelegramId(telegramId);
        await updateLoginStreak(telegramId as string);
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    }
  };

  useEffect(() => {
    if (telegramId && !user && !isLoading) {
      fetchStreak(telegramId);
    }
  }, [telegramId, user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        handleGetUserByTelegramId,
        handleStartSession,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};
