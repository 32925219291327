import React, { FC } from "react";
import styles from "../helpPages.module.css";

import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";
import { useTranslate } from "../../../i18n";

interface ListenRadioProps {
  onClose: () => void;
  username: string;
}

const Background: FC<ListenRadioProps> = ({ onClose, username }) => {
  const translate = useTranslate();
  const background = translate.help_page.background;
  return (
    <div className={styles.contentWrapper}>
      <CloseIcon
        onClick={onClose}
        className={styles.closeIcon}
      />

      <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img6}></div>

      <p className={styles.subtitle}>{background.title_1} </p>
      <p className={styles.subtitle}>{background.title_2}</p>

      <div className={styles.textContent}>
        <p className={styles.text}>{background.text} </p>
      </div>
    </div>
  );
};

export default Background;
