import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CombinedMusiciansAndSongs,
  getCombinedMusiciansAndSongs,
  getLastSong,
  getMusicianAlbums,
  getMusicianAlbumSongs,
  getMusicians,
  LastSong,
  MusiciansAlbumSongsResponse,
  MusiciansAlbumsResponse,
  MusiciansResponse,
  postLastSong,
  postRateSong,
} from "../service/music/music";
import { useUser } from "./UserContext";

interface MusicContextType {
  handleGetMusicians: () => void;
  handleGetCombinedMusiciansAndSongs: () => void;
  handleGetMusicianAlbums: (musicianId: number) => void;
  handleGetAlbumsSongs: (albumId: number) => void;
  handleSelectSong: (selectedSong: MusiciansAlbumSongsResponse) => void;
  musicians: MusiciansResponse[];
  albums: MusiciansAlbumsResponse[];
  songs: MusiciansAlbumSongsResponse[];
  song: MusiciansAlbumSongsResponse | null;
  setSongs: React.Dispatch<React.SetStateAction<MusiciansAlbumSongsResponse[]>>;
  combined: CombinedMusiciansAndSongs | null;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  handlePostRateSong: (
    telegramId: string,
    songId: string,
    score: string
  ) => void;
  isPlayingFromFavorites: boolean;
  setIsPlayingFromFavorites: React.Dispatch<React.SetStateAction<boolean>>;
  lastSong: LastSong;
  handleGetLastSong: () => Promise<void>;
  handlePostLastSong: (telegramId: number, stationId: number) => Promise<void>;
}

const MusicContext = createContext<MusicContextType | undefined>(undefined);

export const MusicProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const [musicians, setMusicians] = useState<MusiciansResponse[]>([]);
  const [albums, setAlbums] = useState<MusiciansAlbumsResponse[]>([]);
  const [songs, setSongs] = useState<MusiciansAlbumSongsResponse[]>([]);
  const [song, setSong] = useState<MusiciansAlbumSongsResponse | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [combined, setCombined] = useState<CombinedMusiciansAndSongs | null>(
    null
  );
  const [isPlayingFromFavorites, setIsPlayingFromFavorites] = useState(false);
  const [lastSong, setLastSong] = useState<LastSong>({
    song: 0,
    telegram_id: user?.telegram_id,
  });
  const handleGetMusicians = async () => {
    try {
      const musicians = await getMusicians();

      if (musicians) {
        setMusicians(musicians);
      } else {
        console.log("Failed to fetch Musicians");
      }
    } catch (error) {
      console.log("Failed to fetch Musicians", error);
    }
  };

  const handleGetCombinedMusiciansAndSongs = async () => {
    try {
      const combined = await getCombinedMusiciansAndSongs();

      if (combined) {
        setCombined(combined);
      } else {
        console.log("Failed to fetch Combined");
      }
    } catch (error) {
      console.log("Failed to fetch Combined", error);
    }
  };

  const handleGetMusicianAlbums = async (musicianId: number) => {
    try {
      const musicianAlbum = await getMusicianAlbums(musicianId);

      if (musicianAlbum) {
        setAlbums(musicianAlbum);
      } else {
        console.log("Failed to fetch MusicianAlbum");
      }
    } catch (error) {
      console.log("Failed to fetch MusicianAlbum", error);
    }
  };

  const handleGetAlbumsSongs = async (albumId: number) => {
    try {
      const albumSongs = await getMusicianAlbumSongs(albumId);

      if (albumSongs) {
        setSongs(albumSongs);
      } else {
        console.log("Failed to fetch songs");
      }
    } catch (error) {
      console.log("Failed to fetch songs", error);
    }
  };

  const handleSelectSong = (selectedSong: MusiciansAlbumSongsResponse) => {
    setSong(selectedSong);
    if (user?.telegram_id) {
      handlePostLastSong(selectedSong.id, user.telegram_id).then(() => {
        handleGetLastSong();
      });
    }
  };

  const handlePostRateSong = async (
    telegramId: string,
    songId: string,
    score: string
  ) => {
    try {
      await postRateSong(telegramId, songId, score);
    } catch (error) {
      console.error("Error posting rating:", error);
    }
  };

  const handleGetLastSong = async (): Promise<void> => {
    try {
      const lastSong = await getLastSong(user.telegram_id);
      setLastSong({
        song: lastSong?.song,
        telegram_id: user?.telegram_id,
      });
    } catch (error: any) {
      if (error.response?.status === 404) {
        console.log("No last song found for this user, setting default.");

        setLastSong({
          song: 0,
          telegram_id: user?.telegram_id,
        });
      } else {
        console.error("Failed to fetch last station", error);
      }
    }
  };

  const handlePostLastSong = async (
    songId: number,
    telegramId: number
  ): Promise<void> => {
    try {
      await postLastSong(songId, telegramId);
    } catch (error) {
      console.error("Failed to post last song", error);
    }
  };

  useEffect(() => {
    if (user?.telegram_id) {
      setLastSong((prevLastSong) => ({
        ...prevLastSong,
        telegram_id: user.telegram_id,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (lastSong.song && combined && combined.songs.length > 0) {
      const songToPlay = combined.songs.find(
        (song) => song.id === lastSong.song
      );

      if (songToPlay) {
        setSong(songToPlay);
        handleGetAlbumsSongs(songToPlay.album);
        console.log("Found and set song:", songToPlay);
      }
    }
  }, [lastSong.song, combined]);

  useEffect(() => {
    if (user?.telegram_id) {
      handleGetMusicians();
      handleGetCombinedMusiciansAndSongs();
      handleGetLastSong();
    }
  }, [user]);

 

  return (
    <MusicContext.Provider
      value={{
        musicians,
        albums,
        songs,
        setSongs,
        song,
        combined,
        lastSong,
        activeTab,
        isPlayingFromFavorites,
        handleGetMusicians,
        handleGetCombinedMusiciansAndSongs,
        handleGetMusicianAlbums,
        handleGetAlbumsSongs,
        handleSelectSong,
        setActiveTab,
        handlePostRateSong,
        setIsPlayingFromFavorites,
        handleGetLastSong,
        handlePostLastSong,
      }}
    >
      {children}
    </MusicContext.Provider>
  );
};

export const useMusic = (): MusicContextType => {
  const context = useContext(MusicContext);
  if (context === undefined) {
    throw new Error("useFavorites must be used within an FavoritesProvider");
  }
  return context;
};
