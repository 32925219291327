import { axiosInstance } from "../axiosConfig/axiosConfig";

export interface LeagueInterface {
  id: number;
  name: string;
  required_impulses: number;
  boost_multiplier: string;
  transition_cost: number;
  icon: string;
}

export interface UserLeaguesResponse {
  current_league: LeagueInterface;
  passed_leagues: LeagueInterface[];
  upcoming_leagues: LeagueInterface[];
}

export interface LeaderboardResponse {
  telegram_id: number;
  username: string;
  first_name: string;
  last_name: string;
  wallet_balance: number;
  rank: number;
  wallet: number;
}
// export async function getLeagues(
//   telegramId: number
// ): Promise<UserLeaguesResponse> {
//   const response = await axiosInstance.get(`/api/user-leagues/`, {
//     params: { telegram_id: telegramId },
//   });
//   return response.data;
// }

export async function getLeagues(
  telegramId: number
): Promise<UserLeaguesResponse> {
  try {
    const response = await fetch(
      `https://radiogram.site/api/user-leagues/?telegram_id=${telegramId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWTYxNjIzOTAyMn0.",
        },
      }
    );

    const data: UserLeaguesResponse = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getAllLeagues() {
  const response = await axiosInstance.get(`/api/leagues/`);

  return response.data;
}

export async function getUserLeagueTopUsers(telegramId: number): Promise<any> {
  const response = await axiosInstance.get(`/api/league-top-users/?telegram_id=${telegramId}`);

  return response.data;
}

export async function getLeague(id: number): Promise<any> {
  const response = await axiosInstance.get(`/api/league/${id}/`);

  return response.data;
}

export async function updateLeague(id: number, leagueId: number): Promise<any> {
  const response = await axiosInstance.post(`/api/transition/`, {
    telegram_id: id,
    league_id: leagueId,
  });

  return response.data;
}

export async function getLeaderBoard(
  telegramId: number
): Promise<LeaderboardResponse[]> {
  try {
    const response = await fetch(
      `https://radiogram.site/api/leaderboard?telegram_id=${telegramId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWTYxNjIzOTAyMn0.",
        },
      }
    );

    const data: LeaderboardResponse[] = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}