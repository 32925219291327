import {axiosInstance} from "../axiosConfig/axiosConfig";

export interface UpdateBalanceDTO {
    "telegram_id": string,
    "points": string
}

export interface BalanceResponse {
    "id": number;
    "balance": string;
    "user": number
}

export async function getWalletByTelegramId(id: string): Promise<BalanceResponse> {
    const response = await axiosInstance.get(
        `/api/wallet/${id}`,
    );
    return response.data;
}

export async function updateWalletBalance(data: UpdateBalanceDTO): Promise<BalanceResponse> {
    const response = await axiosInstance.post(
        `/wallets/update_balance/?telegram_id=${data.telegram_id}&points=${data.points}`,
        {
            data
        }
    );
    return response.data;
}