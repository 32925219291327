import React, {FC} from 'react';

import { ReactComponent as BackIcon } from "../../../assets/icons/goBackIcon.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/goNextIcon.svg";

import styles from "../Onboarding.module.css";

interface ControlsProps {
    disableBackButton: boolean;
    disableNextButton: boolean;
    onBackClick: () => void;
    onNextClick: () => void;
    activeStep: number;
}

const STEPS_CONFIG = [1, 2, 3, 4, 5]

const Controls: FC<ControlsProps> = ({ activeStep, disableBackButton, disableNextButton, onNextClick, onBackClick }) => {
    return (
        <div className={styles.onboardingNavigationControls}>
            <div className={`${styles.controlButton} ${styles.backButton} ${disableBackButton ? styles.disabled : ""}`}>
                <BackIcon onClick={disableBackButton ? () => {} : onBackClick} />
            </div>
            <div className={styles.stepsControll}>
                {STEPS_CONFIG.map((step, index) => (
                    <span key={step} className={`${activeStep >= index + 1 ? styles.active + " " + styles.step : styles.step}`}></span>
                ))}
            </div>

            <div className={`${styles.controlButton} ${styles.nextButton} ${disableNextButton ? styles.disabled : ""}`}>
                <NextIcon onClick={disableNextButton ? () => {} : onNextClick} />
            </div>

        </div>
    );
};

export default Controls;