import { axiosInstance } from "../axiosConfig/axiosConfig";

export interface MusiciansResponse {
  id: number;
  name: string;
  genres: number[];
  country: number;
  photo: string;
}

export interface MusiciansAlbumsResponse {
  id: number;
  title: string;
  musician: number;
  release_date: string;
  photo: string;
}

export interface MusiciansAlbumSongsResponse {
  song: any;
  id: number;
  title: string;
  album: number;
  musician: MusiciansResponse;
  file: string;
  impulse: number;
  average_rating: string;
}

export interface CombinedMusiciansAndSongs {
  songs: MusiciansAlbumSongsResponse[];
  musicians: MusiciansResponse[];
}

export interface LastSong {
  song: number;
  telegram_id: number;
}

export async function getMusicians() {
  const response = await axiosInstance.get(`/api/music/musicians/`);
  return response.data;
}

export async function getMusicianAlbums(musicianId: number) {
  const response = await axiosInstance.get(
    `/api/music/musicians/${musicianId}/albums/`,
    {
      params: { musicianId },
    }
  );
  return response.data;
}

export async function getMusicianAlbumSongs(albumId: number) {
  const response = await axiosInstance.get(
    `/api/music/albums/${albumId}/songs/`,
    {
      params: { albumId },
    }
  );

  return response.data;
}

export async function getCombinedMusiciansAndSongs() {
  const response = await axiosInstance.get("/api/music/combined/");
  return response.data;
}

export async function postRateSong(
  telegramId: string,
  songId: string,
  score: string
) {
  const response = await axiosInstance.post("/api/music/rate-song/", {
    telegram_id: telegramId,
    song_id: songId,
    score: score,
  });
  return response.data;
}

export async function getLastSong(telegram_id: number): Promise<LastSong> {
  const response = await axiosInstance.get(
    `/api/music/last-played-song/${telegram_id}/`,
    {
      params: { telegram_id },
    }
  );
  return response.data;
}

export async function postLastSong(songId: number, telegramId: number) {
  const response = await axiosInstance.post(`/api/music/last-played-song/`, {
    song_id: songId,
    telegram_id: telegramId,
  });
  return response.data;
}
