import { useNavigate } from "react-router-dom";
import { useMusic } from "../../context/MusicContext";
import style from "./CatalogMusicians.module.css";
import { useState } from "react";
import { MusicianAlbums } from "../MusicianAlbums/MusicianAlbums";

export const CatalogMusicians = () => {
  const { musicians } = useMusic();
  const [selectedMusicianId, setSelectedMusicianId] = useState<number | null>(
    null
  );

  const handleClickMusician = (musicianId: number) => {
    setSelectedMusicianId(musicianId);
  };

  const handleClickBack = () => {
    setSelectedMusicianId(null);
  };

  return (
    <>
      {!selectedMusicianId ? (
        <div className={style.wrapperList}>
          {musicians.map((item) => (
            <div
              key={item.id}
              className={style.wrapper}
              onClick={() => handleClickMusician(item.id)}
            >
              <div className={style.wrapperImg}>
                <img
                  className={style.image}
                  src={item.photo}
                  alt={item.name}
                />
              </div>
              <p className={style.name}>{item.name}</p>
            </div>
          ))}
        </div>
      ) : (
        <MusicianAlbums
          musicianId={selectedMusicianId}
          onBackClick={handleClickBack}
        />
      )}
    </>
  );
};
