import { useFavorites } from "../../context/FavoritesContext";

import { ReactComponent as NoteIcon } from "../../assets/icons/stationMusicIcon.svg";
import Icon from "../../assets/icons/favoriteIcon.png";
import FavoriteActive from "../../assets/icons/FavoriteActive.png";

import style from "./FavoritesMusic.module.css";
import { useMusic } from "../../context/MusicContext";
import { useUser } from "../../context/UserContext";
import { useEffect, useState } from "react";
import { MusiciansAlbumSongsResponse } from "../../service/music/music";
import { FavoriteMusicInterface } from "../../service/favorites/favorites";
import { usePlayer } from "../../context/PlayerContext";

interface FavoriteProps {
  value?: string;
  activeTab?: number;
}

export const FavoritesMusic: React.FC<FavoriteProps> = ({
  value,
  activeTab,
}) => {
  const { musicFavorites, handleToggleMusicFavorite } = useFavorites();
  const {
    song,
    handleSelectSong,
    setIsPlayingFromFavorites,
    handlePostLastSong,
  } = useMusic();
  const { user } = useUser();
  const { setIsPlaying } = usePlayer();

  const [isProcessing, setIsProcessing] = useState(false);
  const [filteredFavorites, setFilteredFavorites] = useState<
    FavoriteMusicInterface[]
  >([]);

  const handleFavoriteClick = async (
    event: React.MouseEvent,
    song: MusiciansAlbumSongsResponse
  ) => {
    if (isProcessing) return;
    setIsProcessing(true);

    event.stopPropagation();
    try {
      await handleToggleMusicFavorite(song, user.telegram_id);
    } catch (error) {
      console.error("Failed to update favorites", error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    let newFilteredFavorites: FavoriteMusicInterface[] = [];

    if (value) {
      const lowerValue = value.toLowerCase();
      if (activeTab === 1) {
        newFilteredFavorites = musicFavorites.filter((fav) =>
          fav.song.title.toLowerCase().includes(lowerValue)
        );
        setFilteredFavorites(newFilteredFavorites);
      }
    }
  }, [value, activeTab, musicFavorites]);

  return (
    <div className={style.wrapperList}>
      {value ? (
        <>
          {filteredFavorites.map((son) => (
            <div
              className={`${style.itemWrapper} ${
                son.song.id === song?.id ? style.active : ""
              }`}
              key={son.id}
              onClick={() => {
                handleSelectSong(son.song);
                setIsPlaying(true);
                setIsPlayingFromFavorites(true);
                handlePostLastSong(user.telegram_id, son.id);
              }}
            >
              <button className={style.playButton}>
                <NoteIcon />
              </button>
              <p
                className={style.title}
              >{`${son.song.musician.name} - ${son.song.title}`}</p>
              <div
                className={style.favoriteIcon}
                onClick={(event) => handleFavoriteClick(event, son.song)}
              >
                {musicFavorites?.find((fav) => fav.song.id === son.id) ? (
                  <img
                    src={FavoriteActive}
                    alt="Remove from favorites"
                    className={style.favoriteActiveimg}
                  />
                ) : (
                  <img
                    src={Icon}
                    alt="Add to favorites"
                    className={style.favoriteimg}
                  />
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {musicFavorites.map((music) => {
            return (
              <div
                className={`${style.itemWrapper} ${
                  music.song.id === song?.id ? style.active : ""
                }`}
                key={music.song.id}
                onClick={() => {
                  handleSelectSong(music.song);
                  setIsPlaying(true);
                  setIsPlayingFromFavorites(true);
                  handlePostLastSong(user.telegram_id, music.song.id);
                }}
              >
                <button className={style.playButton}>
                  <NoteIcon />
                </button>
                <p
                  className={style.title}
                >{`${music.song.musician.name} - ${music.song.title}`}</p>
                <div
                  className={style.favoriteIcon}
                  onClick={(event) => handleFavoriteClick(event, music.song)}
                >
                  {musicFavorites?.find(
                    (fav) => fav.song.id === music.song.id
                  ) ? (
                    <img
                      src={FavoriteActive}
                      alt="Remove from favorites"
                      className={style.favoriteActiveimg}
                    />
                  ) : (
                    <img
                      src={Icon}
                      alt="Add to favorites"
                      className={style.favoriteimg}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
