import { useState, useEffect } from "react";

const useWebSocketWithReconnect = (url: string, maxReconnectAttempts = 3, onMessage = (data: number) => {}) => {
    const [ws, setWs] = useState<WebSocket | null>(null);

    const [isConnected, setIsConnected] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
  
    const connectWebSocket = () => {
      const newWs = new WebSocket(url);
  
      newWs.onopen = () => {
        setIsConnected(true);
        setReconnectAttempts(0);
      };
  
      newWs.onclose = () => {
        setIsConnected(false);
        attemptReconnect();
      };
  
      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        setIsConnected(false);
        attemptReconnect();
      };
  
      newWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.balance !== undefined) {
            onMessage(data.balance)
        }
      };
  
      setWs(newWs);
    };
  
    const attemptReconnect = () => {
      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          setReconnectAttempts((prev) => prev + 1);
          connectWebSocket();
        }, 5000);
      } else {
        console.error("Max reconnect attempts reached. Could not reconnect.");
      }
    };
  
    useEffect(() => {
      connectWebSocket();
  
      return () => {
        ws?.close();
      };
    }, []);
  
    return { ws, isConnected };
  };

  export default useWebSocketWithReconnect;