import React, { FC } from "react";
import styles from "../helpPages.module.css";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";
import { useTranslate } from "../../../i18n";

interface TasksProps {
  onClose: () => void;
  username: string;
}

const Tasks: FC<TasksProps> = ({ onClose, username }) => {
  const translate = useTranslate();
  const tasks = translate.help_page.tasks;

  return (
    <div className={styles.contentWrapper}>
      <CloseIcon
        onClick={onClose}
        className={styles.closeIcon}
      />

      <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img4}></div>

      <p className={styles.subtitle}>{tasks.title}</p>

      <div className={styles.textContent}>
        <p className={styles.text}>{tasks.text} </p>
      </div>
    </div>
  );
};

export default Tasks;
