import React, { FC } from "react";
import styles from "../helpPages.module.css";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";
import { useTranslate } from "../../../i18n";

interface TasksProps {
  onClose: () => void;
  username: string;
}

const UpYourRank: FC<TasksProps> = ({ onClose, username }) => {
  const translate = useTranslate();
  const rank = translate.help_page.up_your_rank;
  return (
    <div className={styles.contentWrapper}>
      <CloseIcon
        onClick={onClose}
        className={styles.closeIcon}
      />

      <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img}></div>

      <p className={styles.subtitle}>{rank.title}</p>

      <div className={styles.textContent}>
        <p className={styles.text}>{rank.text} </p>
      </div>
    </div>
  );
};

export default UpYourRank;
