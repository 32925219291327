import { useEffect, useState } from "react";
import {
  CombinedMusiciansAndSongs,
  MusiciansAlbumSongsResponse,
  MusiciansResponse,
} from "../../service/music/music";

import { ReactComponent as NoteIcon } from "../../assets/icons/stationMusicIcon.svg";
import Icon from "../../assets/icons/favoriteIcon.png";
import FavoriteActive from "../../assets/icons/FavoriteActive.png";

import style from "./FilteredCombined.module.css";
import { useMusic } from "../../context/MusicContext";
import { useFavorites } from "../../context/FavoritesContext";
import { useUser } from "../../context/UserContext";
import { FavoriteMusicInterface } from "../../service/favorites/favorites";
import { getCountries, getGenres } from "../../service/stations/stations";

interface FilteredCombinedProps {
  value: string;
  handleClickMusician: (id: number) => void;
  activeTab: number;
}

export const FilteredCombined: React.FC<FilteredCombinedProps> = ({
  value,
  handleClickMusician,
  activeTab,
}) => {
  const [filteredCombines, setFilteredCombines] = useState<
    CombinedMusiciansAndSongs[]
  >([]);

  const { combined } = useMusic();
  const {
    musicFavorites,
    handleGetMusicFavoritesByUser,
    handleToggleMusicFavorite,
  } = useFavorites();
  const { user } = useUser();
  const [isProcessing, setIsProcessing] = useState(false);

  const [genres, setGenres] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [activeGenre, setActiveGenre] = useState(0);
  const [activeCountry, setActiveCountry] = useState(0);



  const handleFavoriteClick = async (
    event: React.MouseEvent,
    song: MusiciansAlbumSongsResponse
  ) => {
    if (isProcessing) return;
    setIsProcessing(true);

    event.stopPropagation();
    try {
      await handleToggleMusicFavorite(song, user.telegram_id);
    } catch (error) {
      console.error("Failed to update favorites", error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    let newFilteredCombines: CombinedMusiciansAndSongs[] = [];

    if (
      combined &&
      typeof combined === "object" &&
      "songs" in combined &&
      "musicians" in combined
    ) {
      newFilteredCombines = [
        {
          songs: combined.songs as MusiciansAlbumSongsResponse[],
          musicians: combined.musicians as MusiciansResponse[],
        },
      ];
    } else if (Array.isArray(combined)) {
      newFilteredCombines = combined;
    }

    if (value) {
      const lowerValue = value.toLowerCase();

      newFilteredCombines = newFilteredCombines
        .map((combine) => ({
          ...combine,
          songs: combine.songs.filter((song) =>
            song.title.toLowerCase().includes(lowerValue)
          ),
          musicians: combine.musicians.filter((musician) =>
            musician.name.toLowerCase().includes(lowerValue)
          ),
        }))
        .filter(
          (combine) => combine.songs.length > 0 || combine.musicians.length > 0
        );
      setFilteredCombines(newFilteredCombines);
    }

    
  }, [value, combined]);

  useEffect(() => {
    if (user) {
      handleGetMusicFavoritesByUser(user.telegram_id);
    }
  }, [user]);

  return (
    <div className={style.wrapperList}>
      {filteredCombines.map((combine) => (
        <div>
          {combine.musicians.map((musician) => (
            <div
              className={style.itemWrapper}
              key={musician.id}
              onClick={() => handleClickMusician(musician.id)}
            >
              <img
                src={musician.photo}
                alt=""
              />
              <p className={style.title}>{musician.name}</p>
            </div>
          ))}

          {combine.songs.map((song) => (
            <div
              className={style.itemWrapper}
              key={song.id}
            >
              <button className={style.playButton}>
                <NoteIcon />
              </button>
              <p
                className={style.title}
              >{`${song.musician.name} - ${song.title}`}</p>
              <div
                className={style.favoriteIcon}
                onClick={(event) => handleFavoriteClick(event, song)}
              >
                {musicFavorites?.find((fav) => fav.song.id === song.id) ? (
                  <img
                    src={FavoriteActive}
                    alt="Remove from favorites"
                    className={style.favoriteActiveimg}
                  />
                ) : (
                  <img
                    src={Icon}
                    alt="Add to favorites"
                    className={style.favoriteimg}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
