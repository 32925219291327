import React from 'react';
import PlayerControls from '../../components/PlayerControls/PlayerControls';

const Player: React.FC<{ lang: "en" | "ru", onTabChange: (value: number) => void }> = ({ lang, onTabChange }) => {
    return (
        <PlayerControls onTabChange={onTabChange} lang={lang} />
    );
};

export default Player;
