import React, {FC, useState} from 'react';



import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";

import styles from "./Onboarding.module.css";
import Controls from "./Controlls/Controlls";

interface OnboardingInterface {
    username: string;
}


const Onboarding: FC<OnboardingInterface> = ({ username = "username" }) => {
    const [activeStep, setActiveStep] = useState(1);

    const renderStep = () => {
        switch (activeStep) {
            case 1:
                return <Step1 username={username} />
            case 2:
                return <Step2 username={username} />
            case 3:
                return <Step3 username={username} />
            case 4:
                return <Step4 username={username} />
            case 5:
                return <Step5 username={username} />
        }
    }

    return (
        <div className={styles.onboardingWrapper}>
            {renderStep()}
            <Controls activeStep={activeStep} disableBackButton={activeStep === 1} disableNextButton={activeStep === 5} onBackClick={() => setActiveStep(prevState => prevState - 1)} onNextClick={() => setActiveStep(prevState => prevState + 1)} />
        </div>
    );
};

export default Onboarding;