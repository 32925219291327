import React, { FC } from 'react';
import styles from "./copyright.module.css";
import { useTranslate } from '../../i18n';

const Copyright: FC<{ lang: "en" | "ru" }> = ({ lang }) => {
    const translate = useTranslate();

    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.title}>{translate.copyright}</h1>
            <p className={styles.subtitle}>{translate.copyright_notice}</p>
            <p className={styles.paragraph}>{translate.copyright_text1}</p>
            <p className={styles.paragraph}>{translate.copyright_text2}</p>
            <p className={styles.paragraph}>{translate.copyright_text3}</p>
            <p className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate.copyright_text4 }}></p>
        </div>
    );
};

export default Copyright;
