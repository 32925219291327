import React from "react";
import ReactDOM from "react-dom/client";
import { SDKProvider } from "@telegram-apps/sdk-react";
import "./index.css";
import App from "./App";
import { UserProvider } from "./context/UserContext";
import { FavoritesProvider } from "./context/FavoritesContext";
import { StationsProvider } from "./context/StationsContext";
import { LeaguesProvider } from "./context/LeaguesContext";
import { GamesProvider } from "./context/GameContext";
import { PlayerPrivider } from "./context/PlayerContext";
import { MusicProvider } from "./context/MusicContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <SDKProvider
      acceptCustomStyles
      debug
    >
      <PlayerPrivider>
        <UserProvider>
          <FavoritesProvider>
            <LeaguesProvider>
              <GamesProvider>
                <MusicProvider>
                  <StationsProvider>
                    <App />
                  </StationsProvider>
                </MusicProvider>
              </GamesProvider>
            </LeaguesProvider>
          </FavoritesProvider>
        </UserProvider>
      </PlayerPrivider>
    </SDKProvider>
  </React.StrictMode>
);
