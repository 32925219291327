import React, {FC} from 'react';
import styles from "../helpPages.module.css";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";
import { useTranslate } from '../../../i18n';


interface InviteProps {
    onClose: () => void;
    username: string;
}

const Invite: FC<InviteProps> = ({ onClose, username }) => {
  const translate = useTranslate();
  const invite = translate.help_page.invite;
    return (
        <div className={styles.contentWrapper}>
            <CloseIcon onClick={onClose} className={styles.closeIcon} />

            <h1 className={styles.title}>{username}!</h1>

            <div className={styles.img3}></div>

        <p className={styles.subtitle}>{invite.title}</p>

            <div className={`${styles.textContent} ${styles.textContentCenter}`}>
                <p className={styles.text}>
                    {invite.text_1}
                </p>
                <p className={styles.text}>
                {invite.text_2}
                </p>
                <p className={styles.text}>
                {invite.text_3}
                </p>
                <p className={styles.blueText}>
                {invite.text_4}
                </p>
            </div>
        </div>
    );
};

export default Invite;