import React, { FC } from "react";
import styles from "../helpPages.module.css";

import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";
import { useTranslate } from "../../../i18n";

interface ListenRadioProps {
  onClose: () => void;
  username: string;
}

const ListenRadio: FC<ListenRadioProps> = ({ onClose, username }) => {
  const translate = useTranslate();
  const listenRadio = translate.help_page.listen_radio;
  return (
    <div className={styles.contentWrapper}>
      <CloseIcon
        onClick={onClose}
        className={styles.closeIcon}
      />
      <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img2}></div>

      <p className={styles.subtitle}>{listenRadio.title_1}</p>
      <p className={styles.subtitle}>{listenRadio.title_2}</p>

      <div className={`${styles.textContent} ${styles.textContentCenter}`}>
        <p className={styles.text}>{listenRadio.text_1}</p>
        <p className={styles.text}>{listenRadio.text_2} </p>
        <p className={styles.text}>{listenRadio.text_3} </p>
        <p className={styles.text}>
          <p>{listenRadio.text_4}</p>
          {listenRadio.text_5}
        </p>
        <p className={styles.blueText}>{listenRadio.text_6}</p>
      </div>
    </div>
  );
};

export default ListenRadio;
