import React, { FC } from "react";
import styles from "../Onboarding.module.css";
import { useTranslate } from "../../../i18n";

interface OnboardingInterface {
  username: string;
}

const Step4: FC<OnboardingInterface> = ({ username }) => {
  const translate = useTranslate();
  const step = translate.onboarding.step_4;
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img4}></div>

      <p className={styles.subtitle}>{step.title}</p>

      <div className={`${styles.textContent} ${styles.textContentCenter}`}>
        <p className={styles.text}>{step.text}</p>
        
        <p className={styles.blueText}>{step.text_5}</p>
      </div>
    </div>
  );
};

export default Step4;
