import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser } from "./UserContext";
import { getCheckSpinStatus, getSpinWheel } from "../service/games/games";


interface GamesContextProps {
  lastLaunchDate: string | null;
  fetchSpinResult: () => Promise<any | null>;
  status: boolean;
  fetchSpinStatus: () => Promise<any | null>;
}
interface ErrorResponse {
  time_left: string;
  status: string;
  message?: string;
  time_left_seconds: number;
}

const GamesContext = createContext<GamesContextProps | undefined>(undefined);

export const GamesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const [lastLaunchDate, setLastLaunchDate] = useState<string | null>(null);
  const [status, setStatus] = useState(true);

  const fetchSpinStatus = async () => {
    try {
      const data = await getCheckSpinStatus(user.telegram_id);
      if (data.status === "success") {
        return setStatus(true);
      }
    } catch (error) {
      setStatus(false);
    }
  };

  const fetchSpinResult = async () => {
    try {
      const data = await getSpinWheel(user.telegram_id);
      return data;
    } catch (error: any) {
      console.log("Error in fetchSpinResult:", error);

      if (error.response?.data) {
        const errorData = error.response.data;
        const timeLeft = errorData.time_left_seconds;

        console.log("Time left:", timeLeft);
        setLastLaunchDate(timeLeft);

        return {
          status: "error",
          message: errorData.message,
          time_left: timeLeft,
          time_left_seconds: errorData.time_left_seconds,
        } as ErrorResponse;
      }

      return {
        status: "error",
        message: "Unexpected error occurred",
        time_left: "",
        time_left_seconds: 0,
      } as ErrorResponse;
    }
  };

  useEffect(() => {
    console.log("lastLaunchDate updated:", lastLaunchDate);
  }, [lastLaunchDate]);

  return (
    <GamesContext.Provider
      value={{ lastLaunchDate, fetchSpinResult, status, fetchSpinStatus }}
    >
      {children}
    </GamesContext.Provider>
  );
};

export const useGames = () => {
  const context = useContext(GamesContext);
  if (context === undefined) {
    throw new Error("useGames must be used within a GamesProvider");
  }
  return context;
};
