
import React, { FC, useState } from "react";
import styles from "../Onboarding.module.css";
import {  axiosInstance  } from "../../../service/axiosConfig/axiosConfig";
import {  useUser  } from "../../../context/UserContext";
import UserAgreement from "../../../pages/userAgreement/userAgreement";
import { useTranslate } from "../../../i18n";

interface OnboardingInterface {
  username: string;
}

const Step5: FC<OnboardingInterface> = ({ username }) => {
  const { handleGetUserByTelegramId } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const translate = useTranslate();
  const step = translate.onboarding.step_5;

  const handleClaimReward = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const telegramId = urlParams.get("telegram_id");

    const data = await axiosInstance
      .post("/api/update-terms/", {
        telegram_id: telegramId,
        is_terms_accepted: true,
      })
      .then((res) => res.data);

    if (data.status === "updated") {
      await handleGetUserByTelegramId(telegramId as string);
    }
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {isOpen ? (
        <div className={styles.agreementContentWrapper}>
          <UserAgreement
            onClick={() => handleOpen()}
            button={true}
          />
        </div>
      ) : (
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>{username}!</h1>

      <div className={styles.img5}></div>

      <p className={styles.subtitle}>{step.subtitle_1}</p>
      <p className={styles.subtitle}>{step.subtitle_2}</p>

          <div className={`${styles.textContent} ${styles.textContentCenter}`}>
            <p className={styles.text}>
            {step.text_1}
              <p>
                  <span
                    className={styles.link}
                    onClick={() => handleOpen()}>
                  {step.text_2}
                </span>{" "}
                {step.text_3}
              </p>
              <p>{step.text_4}</p>
            </p>
            <p className={styles.amount}>1000</p>
            <p className={styles.impulse}>IMPULSE</p>
            <button
              className={styles.claimButton}
              onClick={handleClaimReward}
            >
              <p>{step.text_5}</p>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Step5;
