export interface GamesBadResponse {
  points_awarded?: number;
  time_left?: string;
  status?: string;
  message?: string;
  time_left_seconds?: number;
}

export async function getSpinWheel(telegramId: number): Promise<any> {
  const response = await fetch(
    `https://radiogram.site/api/game/spin_wheel/${telegramId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWTYxNjIzOTAyMn0.",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw {
      response: {
        data: data as GamesBadResponse,
        status: response.status,
      },
    };
  }

  return data;
}
export async function getCheckSpinStatus(telegramId: number): Promise<any> {
  const response = await fetch(
    `https://radiogram.site/api/game/check_spin_status/${telegramId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWTYxNjIzOTAyMn0.",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw {
      response: {
        data: data as GamesBadResponse,
        status: response.status,
      },
    };
  }

  return data;
}
