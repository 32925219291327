import React, { FC, useEffect, useState } from "react";

import ListenRadio from "../../components/HelpPages/ListenRadio/ListenRadio";
import Invite from "../../components/HelpPages/Invite/ListenRadio";
import Tasks from "../../components/HelpPages/Tasks/ListenRadio";
import Games from "../../components/HelpPages/Games/ListenRadio";
import Background from "../../components/HelpPages/Background/ListenRadio";
import UpYourRank from "../../components/HelpPages/UpYourRank/ListenRadio";

import { useUser } from "../../context/UserContext";

import styles from "./help.module.css";
import { useTranslate } from "../../i18n";

const Help: FC = () => {
  const { user, handleGetUserByTelegramId } = useUser();
  const [activeWindow, setActiveWindow] = useState<number>(0);
  const translate = useTranslate();

  const handleClose = () => setActiveWindow(0);

  const renderActiveWindow = () => {
    switch (activeWindow) {
      case 0:
        return null;

      case 1:
        return (
          <ListenRadio
            onClose={handleClose}
            username={user?.first_name}
          />
        );
      case 2:
        return (
          <Invite
            onClose={handleClose}
            username={user?.first_name}
          />
        );
      case 3:
        return (
          <Tasks
            onClose={handleClose}
            username={user?.first_name}
          />
        );
      case 4:
        return (
          <Games
            onClose={handleClose}
            username={user?.first_name}
          />
        );
      case 5:
        return (
          <UpYourRank
            onClose={handleClose}
            username={user?.first_name}
          />
        );
      case 6:
        return (
          <Background
            onClose={handleClose}
            username={user?.first_name}
          />
        );
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const telegramId = urlParams.get("telegram_id");
    if (telegramId) {
      handleGetUserByTelegramId(telegramId);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.pageWrapper}>
        {!!activeWindow && (
          <div className={styles.onboardingWrapper}>{renderActiveWindow()}</div>
        )}
        <h1 className={styles.pageTitle}>{translate.help}</h1>
        <ul className={styles.faqList}>
          <div
            onClick={() => setActiveWindow(1)}
            className={styles.item}
          >
            {translate.listen_radio}
          </div>
          <div
            onClick={() => setActiveWindow(2)}
            className={styles.item}
          >
            <span dangerouslySetInnerHTML={{ __html: translate.invite }} />
          </div>
          <div
            onClick={() => setActiveWindow(3)}
            className={styles.item}
          >
            {translate.complete_tasks}
          </div>
          <div
            onClick={() => setActiveWindow(4)}
            className={styles.item}
          >
            {translate.play_games}
          </div>
          <div
            onClick={() => setActiveWindow(5)}
            className={styles.item}
          >
            {translate.up_your_rank}
          </div>
          <div
            onClick={() => setActiveWindow(6)}
            className={styles.item}
          >
            {translate.not_working_in_the_background}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Help;
