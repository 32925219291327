import React, { FC, useEffect, useState } from "react";
import style from "./userScoreMusicians.module.css";
import { useTranslate } from "../../i18n";
import { useLeagues } from "../../context/LeaguesContext";
import { ReactComponent as VolumeIcon } from "../../assets/icons/player/volumtIcon.svg";
import VolumeControlMusicians from "../VolumeControlMusicians/VolumeControlMusicians";
import { StarRating } from "../StarRating/StarRating";

const UserScore: FC<{
  score: string;
  lang: string;
  isPlaying: boolean;
  onTabChange: (value: number) => void;
  music: "song" | "station";
  volume?: number;
  onVolumeChange?: (volume: number) => void;
  duration: number;
  progress: number;
}> = ({
  score = "0.00",
  lang,
  isPlaying = false,
  onTabChange,
  music,
  volume = 50,
  onVolumeChange,
  duration,
  progress,
}) => {
  const translate = useTranslate();
  const { usersLeague } = useLeagues();
  const [isOpenVolume, setIsOpenVolume] = useState(false);
  // const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         if (isPlaying) {
  //             setProgress(prev => (prev >= 100 ? 0 : prev + (100 / (60 * 1000 / 5))));
  //         } else {
  //             setProgress(0)
  //         }
  //     }, 5);

  //     return () => clearInterval(interval);
  // }, [isPlaying]);

  const handleToggleOpenVolume = () => {
    setIsOpenVolume(!isOpenVolume);

    // if (isOpenVolume) {
    //   setInterval(() => {
    //     setIsOpenVolume(false);
    //   }, 5000);
    // }
  };

  // console.log(isOpenVolume);
  const progressPercent = duration > 0 ? (progress / duration) * 100 : 0;

  return (
    <div
      className={style.progressWrapper}
      style={{
        background: `conic-gradient(#854AFF ${progressPercent}%, transparent 0%)`,
      }}
    >
      <div className={style.bWrapper}>
        <div className={style.balanceWrapper}>
          {music === "station" ? (
            <p
              onClick={() => onTabChange(7)}
              className={style.leagueName}
            >
              {usersLeague?.name}
            </p>
          ) : (
            <div className={style.volumeWrapper}>
              <VolumeIcon onClick={handleToggleOpenVolume} />
              {isOpenVolume && (
                <div className={style.volumeChange}>
                  <VolumeControlMusicians
                    onClose={handleToggleOpenVolume}
                    volume={volume}
                    onVolumeChange={onVolumeChange ? onVolumeChange : () => {}}
                  />
                </div>
              )}
            </div>
          )}

          <p className={style.balanceTitle}>{translate.balance_title}</p>
          <h2 className={style.balanceScore}>{score}</h2>
          <h3 className={style.balanceSubtitle}>
            {translate.balance_subtitle}
          </h3>
          {music === "song" && <StarRating />}
        </div>
      </div>
    </div>
  );
};

export default UserScore;
