import React from "react";

import { ReactComponent as PlayerIcon } from "../../assets/icons/navigation/radioIcon.svg";
import { ReactComponent as StationsIcon } from "../../assets/icons/navigation/stations.svg";
import { ReactComponent as Friends } from "../../assets/icons/navigation/friendsIcon.svg";
import { ReactComponent as TasksIcon } from "../../assets/icons/navigation/tasks.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/navigation/walletIcon.svg";

import { ReactComponent as PlayerArtistIcon } from "../../assets/icons/navigation/player.svg";
import { ReactComponent as CatalogIcon } from "../../assets/icons/navigation/catalog.svg";
import { ReactComponent as FavoritesIcon } from "../../assets/icons/navigation/favorites.svg";

import { ReactComponent as PlayerIconActive } from "../../assets/icons/navigation/radioActive.svg";
import { ReactComponent as StationsIconActive } from "../../assets/icons/navigation/stationsActive.svg";
import { ReactComponent as FriendsActive } from "../../assets/icons/navigation/friendsActiveIcon.svg";
import { ReactComponent as TasksIconActive } from "../../assets/icons/navigation/tasksActiveIcon.svg";
import { ReactComponent as WalletIconActive } from "../../assets/icons/navigation/walletActiveIcon.svg";

import { ReactComponent as PlayerArtistIconActive } from "../../assets/icons/navigation/playerActive.svg";
import { ReactComponent as CatalogIconActive } from "../../assets/icons/navigation/catalogActive.svg";
import { ReactComponent as FavoritesIconActive } from "../../assets/icons/navigation/favoritesActive.svg";
import { useTranslate } from "../../i18n";

import styles from "./tabNavigation.module.css";

const TabNavigation: React.FC<{
  value: number;
  menuOpen: boolean;
  onMenuOpen: () => void;
  isArtistOpen?: boolean;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  lang: "ru" | "en";
}> = ({ value, setValue, onMenuOpen, menuOpen, lang, isArtistOpen }) => {
  const translate = useTranslate();

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bottomNavigation}>
        {!isArtistOpen ? (
          <>
            <div
              onClick={() => handleChange(0)}
              className={`${styles.navigationItem} ${
                value === 0 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 0 && !menuOpen ? <PlayerIconActive /> : <PlayerIcon />}
              <span className={styles.navigationTitle}>{translate.radio}</span>
            </div>
            <div
              onClick={() => handleChange(1)}
              className={`${styles.navigationItem} ${
                value === 1 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 1 && !menuOpen ? (
                <StationsIconActive />
              ) : (
                <StationsIcon />
              )}
              <span className={styles.navigationTitle}>
                {translate.stations}
              </span>
            </div>
            <div
              onClick={() => handleChange(2)}
              className={`${styles.navigationItem} ${
                value === 2 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 2 && !menuOpen ? <FriendsActive /> : <Friends />}
              <span className={styles.navigationTitle}>
                {translate.friends}
              </span>
            </div>
            <div
              onClick={() => handleChange(3)}
              className={`${styles.navigationItem} ${
                value === 3 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 3 && !menuOpen ? <TasksIconActive /> : <TasksIcon />}
              <span className={styles.navigationTitle}>{translate.tasks}</span>
            </div>
            <div
              onClick={() => handleChange(4)}
              className={`${styles.navigationItem} ${
                value === 4 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 4 && !menuOpen ? <WalletIconActive /> : <WalletIcon />}
              <span className={styles.navigationTitle}>{translate.wallet}</span>
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => handleChange(0)}
              className={`${styles.navigationItem} ${
                value === 0 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 0 && !menuOpen ? (
                <PlayerArtistIconActive />
              ) : (
                <PlayerArtistIcon />
              )}
              <span className={styles.navigationTitle}>Player</span>
            </div>
            <div
              onClick={() => handleChange(1)}
              className={`${styles.navigationItem} ${
                value === 1 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 1 && !menuOpen ? (
                <CatalogIconActive />
              ) : (
                <CatalogIcon />
              )}
              <span className={styles.navigationTitle}>Catalog</span>
            </div>
            <div
              onClick={() => handleChange(2)}
              className={`${styles.navigationItem} ${
                value === 2 && !menuOpen && styles.activeNavigationItem
              }`}
            >
              {value === 2 && !menuOpen ? (
                <FavoritesIconActive />
              ) : (
                <FavoritesIcon />
              )}
              <span className={styles.navigationTitle}>Favorites</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TabNavigation;
