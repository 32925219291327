import { axiosInstance } from "../axiosConfig/axiosConfig";
import { MusiciansAlbumSongsResponse } from "../music/music";

export interface FavoriteStationInterface {
  id: number;
  user: number;
  station: number;
}

export interface FavoriteMusicInterface {
  id: number;
  user_id: number;
  song: MusiciansAlbumSongsResponse;
}

export async function getAllFavoritesByTelegramId(id: number) {
  const response = await axiosInstance.get(`/api/users/${id}/favorites/`);
  return response.data;
}
export async function getAllFavoritesMusic(id: number) {
  const response = await axiosInstance.get(`/api/music/favorites/${id}/`);
  return response.data;
}

export async function toggleFavorite(stationId: string, telegramId: number) {
  const response = await axiosInstance.post(`/api/toggle-favorite/`, {
    telegram_id: telegramId,
    station_id: stationId,
  });
  return response.data;
}

export async function toggleMusicFavorite(songId: string, telegramId: number) {
  const response = await axiosInstance.post(`/api/music/toggle-favorite/`, {
    telegram_id: telegramId,
    song_id: songId,
  });
  return response.data;
}
