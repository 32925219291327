import React, { FC } from "react";
import styles from "./userAgreement.module.css";
import { useTranslate } from "../../i18n";

type Props = {
  onClick?: () => void;
  button: boolean;
};

const UserAgreement: FC<Props> = ({ onClick, button }) => {
  const translate = useTranslate();
  const t = translate.user_agreement;

  return (
    <div className={styles.pageWrapper}>
      <h1 className={styles.title}>{t.title}</h1>

      <p className={styles.paragraph}>{t.text_1}</p>
      <p className={styles.paragraph}>{t.text_2}</p>
      <p className={styles.paragraph}>{t.text_3}</p>
      <p className={styles.subtitle}>{t.list_1.title}</p>
      <ul>
        <li className={styles.listItem}>{t.list_1.item_1}</li>
        <li className={styles.listItem}>{t.list_1.item_2}</li>
        <li className={styles.listItem}>{t.list_1.item_3}</li>
      </ul>
      <p className={styles.subtitle}>{t.list_2.title}</p>
      <ul>
        <li className={styles.listItem}>{t.list_2.item_1}</li>
        <li className={styles.listItem}>{t.list_2.item_2}</li>
        <li className={styles.listItem}>{t.list_2.item_3}</li>
        <li className={styles.listItem}>{t.list_2.item_4}</li>
        <li className={styles.listItem}>{t.list_2.item_5}</li>
      </ul>
      <p className={styles.subtitle}>{t.list_3.title}</p>
      <ul>
        <li className={styles.listItem}>{t.list_3.item_1}</li>
        <li className={styles.listItem}>{t.list_3.item_2}</li>
        <li className={styles.listItem}>{t.list_3.item_3}</li>
        <li className={styles.listItem}>{t.list_3.item_4}</li>
      </ul>
      <p className={styles.subtitle}>{t.list_4.title}</p>
      <ul>
        <li className={styles.listItem}>{t.list_4.item_1}</li>
        <li className={styles.listItem}>{t.list_4.item_2}</li>
        <li className={styles.listItem}>{t.list_4.item_3}</li>
      </ul>
      <p className={styles.subtitle}>{t.list_5.title}</p>
      <ul>
        <li className={styles.listItem}>{t.list_5.item_1}</li>
        <li className={styles.listItem}>{t.list_5.item_2}</li>
        <li className={styles.listItem}>{t.list_5.item_3}</li>
        <li className={styles.listItem}>{t.list_5.item_4}</li>
        <li className={styles.listItem}>{t.list_5.item_5}</li>
      </ul>
      <p className={styles.subtitle}>{t.list_6.title}</p>
      <ul>
        <li className={styles.listItem}>{t.list_6.item_1}</li>
        <li className={styles.listItem}>{t.list_6.item_2}</li>
        <li className={styles.listItem}>{t.list_6.item_3}</li>
        <li className={styles.listItem}>{t.list_6.item_4}</li>
      </ul>

      {button && (
        <div className={styles.doneWrapper}>

        <p
          onClick={onClick}
          className={styles.done}
        >
          I’m agree
        </p>
        </div>
      )}
    </div>
  );
};

export default UserAgreement;
