import React, { useEffect, useRef } from "react";
import { Slider, IconButton } from "@mui/material";
import { ReactComponent as VolumeIcon } from "../../assets/icons/player/volumtIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { styled } from "@mui/system";

const VolumeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "255px",
  height: "34px",
  color: "#FFFFFF",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  background:
    "radial-gradient(162.98% 162.72% at 50.25% 50.25%, rgba(71, 17, 82, 0.3) 0%, rgba(0, 178, 255, 0.05) 100%)",
  backdropFilter: "blur(35.4px)",
  boxShadow: "0 7px 6px 0 #000",
  
  padding: '0 5px',
}));

const VolumeSlider = styled(Slider)(({ theme }) => ({
  color: "#4A78F1",
  height: 6,
  marginRight: '5px',
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    background: "#9C5397",
    boxShadow: "0px 4px 4.9px rgba(234.55, 173.19, 255, 0.55) inset",
  },
  "& .MuiSlider-track": {
    border: "none",
    background:
      "linear-gradient(90deg, rgba(213, 2, 255, 0.7) 0%, rgba(0, 179, 255, 0.7) 100%)",
    backdropFilter: "blur(20.3px)",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#6E0DA7",
  },
}));

interface VolumeControlProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
  onClose: () => void;
}

const VolumeControlMusicians: React.FC<VolumeControlProps> = ({
  volume,
  onVolumeChange,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    onVolumeChange(newValue as number);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <VolumeContainer ref={containerRef}>
      <IconButton onClick={() => onVolumeChange(0)} aria-label="volume">
        <VolumeIcon />
      </IconButton>
      <VolumeSlider
        value={volume}
        onChange={handleVolumeChange}
        aria-labelledby="continuous-slider"
      />
      <CloseIcon onClick={onClose} />
    </VolumeContainer>
  );
};

export default VolumeControlMusicians;
