import style from "./liderItem.module.css";

import ProfileImg from "../../assets/img/liderUser.png";
import { useUser } from "../../context/UserContext";

type Props = {
  userPhoto: string;
  userName: string;
  balance: number | string;
  rank: number;
};

export const LiderItem: React.FC<Props> = ({
  userPhoto,
  userName,
  balance,
  rank,
}) => {
  const { user, handleGetUserByTelegramId } = useUser();

  const getUserName = (userName: number) => {
    handleGetUserByTelegramId(userName.toString())

    return user.first_name
  }
  return (
    <div className={style.aboutUser}>
      <div className={style.datailInfoUser}>
        <div className={style.imageWrapper}>
          
        <img
          className={style.profileImage}
          src={userPhoto ? userPhoto : ProfileImg}
          alt=""
        />
        </div>
        <div className={style.infoWrapper}>
          <h2 className={style.profileName}>{userName}</h2>

          <p className={style.balance}>{balance} Impulse</p>
        </div>
      </div>
      <p>#{rank}</p>
    </div>
  );
};
